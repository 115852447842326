/**
 * @Description:
 * @Author: OBKoro1
 * @Date: 2021-04-28 15:19:50
 * @LastEditors: chenchen
 * @LastEditTime: 2021-05-07 16:54:10
 */
import Vue from "vue"
import VueRouter from "vue-router"
import Index from "../views/index.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "Index",
		component: Index
	}
]

const router = new VueRouter({
	routes
})

export default router
