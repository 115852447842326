<template>
  <div class="home"
       @scroll="onPageScroll">
    <!-- 页头 -->
    <div class="home__header"
         ref="header">
      <!-- LOGO -->
      <div class="home__header--logo">
        <svg width="703px"
             height="343px"
             viewBox="0 0 703 343"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>synapsetec</title>
          <g id="v0.4（会议"
             stroke="none"
             stroke-width="1"
             fill="none"
             fill-rule="evenodd">
            <g id="synapsetec">
              <rect id="矩形"
                    fill-opacity="0"
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="703"
                    height="343"></rect>
              <g id="shape_logo"
                 transform="translate(288.430967, 14.682556)"
                 fill="#f3f3f3"
                 fill-rule="nonzero">
                <g id="group">
                  <path d="M74,70.1 C73,69.9 71.8,69.8 70.5,69.6 C59.6,68.1 48,65.7 48,56.9 C48,49.1 56.8,44 70.4,44 C82.9,44 92.3,50.1 94.8,55.9 C99.7,67.3 111.8,73.4 123,70 C134.5,66.6 140.4,55 136.9,43 C129.4,17.7 102.1,0 70.5,0 C53.4,0 37,5.2 24.5,14.6 C10.1,25.3 2,40.7 2,56.9 C2,67.9 5.6,83.8 22.6,96.5 C24.1,97.7 26.3,97.3 27.5,95.8 C28.7,94.3 28.3,92.1 26.8,90.9 C12.1,79.9 9,66.3 9,56.9 C9,42.9 16.1,29.6 28.6,20.2 C39.9,11.7 54.8,7 70.4,7 C98.9,7 123.5,22.6 130.1,45 C131.3,49 131,53 129.3,56.4 C127.6,59.8 124.7,62.2 120.9,63.3 C113.1,65.6 104.6,61.2 101.1,53.1 C97.8,45.3 86.7,36.9 70.3,36.9 C52.4,36.9 40.9,44.7 40.9,56.8 C40.9,72.5 60.1,75.1 69.4,76.4 C70.6,76.6 71.8,76.7 72.8,76.9 C109.2,82.3 131.8,101.4 131.8,126.6 C131.8,140.5 124.7,153.9 112.2,163.3 C100.9,172 86,176.7 70.4,176.7 C48.1,176.7 28.3,167.5 17.3,152.1 C16.2,150.5 14,150.2 12.4,151.3 C10.8,152.4 10.5,154.6 11.6,156.2 C23.9,173.4 45.8,183.7 70.4,183.7 C87.5,183.7 103.9,178.5 116.4,169.1 C130.6,158.4 138.8,143 138.8,126.8 C138.8,97.8 113.9,76 74,70.1 Z"
                        id="路径"></path>
                  <rect id="矩形"
                        x="15.8"
                        y="120.5"
                        width="9.6"
                        height="6"></rect>
                  <path d="M30,126.5 L87.1,126.5 C88.3,129.9 91.6,132.3 95.4,132.3 C100.3,132.3 104.2,128.3 104.2,123.5 C104.2,118.7 100.2,114.7 95.4,114.7 C91.6,114.7 88.3,117.1 87.1,120.5 L30,120.5 L30,126.5 Z M95.4,120.7 C97,120.7 98.2,122 98.2,123.5 C98.2,125 96.9,126.3 95.4,126.3 C93.9,126.3 92.6,125 92.6,123.5 C92.6,122 93.8,120.7 95.4,120.7 Z"
                        id="形状"></path>
                  <path d="M83.2,115 C88.1,115 92,111 92,106.2 C92,101.4 88,97.4 83.2,97.4 C79.4,97.4 76.1,99.8 74.9,103.2 L46.3,103.2 L46.3,109.2 L74.9,109.2 C76.1,112.6 79.4,115 83.2,115 Z M83.2,103.4 C84.8,103.4 86,104.7 86,106.2 C86,107.7 84.7,109 83.2,109 C81.7,109 80.4,107.7 80.4,106.2 C80.4,104.7 81.6,103.4 83.2,103.4 Z"
                        id="形状"></path>
                  <rect id="矩形"
                        x="21.6"
                        y="103.2"
                        width="6.4"
                        height="6"></rect>
                  <rect id="矩形"
                        x="31.8"
                        y="103.2"
                        width="10.7"
                        height="6"></rect>
                  <rect id="矩形"
                        x="5.77315973e-15"
                        y="138.1"
                        width="7.4"
                        height="6"></rect>
                  <rect id="矩形"
                        x="11.2"
                        y="138.1"
                        width="13.6"
                        height="6"></rect>
                  <path d="M28.6,144.1 L74.9,144.1 C76.1,147.5 79.4,149.9 83.2,149.9 C88.1,149.9 92,145.9 92,141.1 C92,136.3 88,132.3 83.2,132.3 C79.4,132.3 76.1,134.7 74.9,138.1 L28.6,138.1 L28.6,144.1 Z M83.2,138.3 C84.8,138.3 86,139.6 86,141.1 C86,142.6 84.7,143.9 83.2,143.9 C81.7,143.9 80.4,142.7 80.4,141.1 C80.4,139.5 81.6,138.3 83.2,138.3 Z"
                        id="形状"></path>
                </g>
              </g>
              <g id="shape_maintext"
                 transform="translate(20.579520, 227.447444)"
                 fill="#f3f3f3"
                 fill-rule="nonzero"
                 stroke="#f3f3f3"
                 stroke-width="0.155">
                <g id="group"
                   transform="translate(-0.000000, 0.000000)">
                  <path d="M4.46,41.91 L36.61,41.91 L38.38,31.22 L2.7,31.22 L7.16,0 L59.75,0 L57.98,15.15 L40.14,15.15 L41.07,8.92 L24.07,8.92 L21.37,22.3 L57.05,22.3 L53.52,46.37 C53.52,47.2366667 53.32,48.0566667 52.92,48.83 C52.52,49.6033333 51.9766667,50.27 51.29,50.83 C50.61,51.39 49.8533333,51.8233333 49.02,52.13 C48.18,52.4366667 47.2966667,52.59 46.37,52.59 L46.37,52.59 L-7.10542736e-15,52.59 L1.77,38.29 L4.46,41.91 Z"
                        id="路径"></path>
                  <polygon id="路径"
                           points="95.24036 19.61 108.62036 1.77 106.86036 0 128.23036 0 102.40036 31.22 99.70036 52.59 81.86036 52.59 84.56036 31.22 69.41036 2.6 66.72036 0 85.49036 0"></polygon>
                  <polygon id="路径"
                           points="138.7286 0 160.1986 0 178.8686 32.99 183.3286 0 198.5686 0 191.4186 52.59 173.5786 52.59 155.7386 20.44 151.2786 52.59 135.1986 52.59 142.3586 3.53"></polygon>
                  <path d="M224.59284,0 L261.20284,0 L268.26284,52.59 L252.28284,52.59 L250.42284,38.29 L228.12284,38.29 L221.89284,52.59 L206.75284,52.59 L227.28284,2.6 L224.59284,0 Z M246.89284,8.92 L240.66284,8.92 L231.74284,29.36 L249.58284,29.36 L246.89284,8.92 Z"
                        id="形状"></path>
                  <path d="M279.967,0 L337.947,0 L334.417,28.53 C333.923667,30.3233333 332.950333,31.81 331.497,32.99 C330.037,34.1633333 328.627,34.75 327.267,34.75 L327.267,34.75 L296.047,34.75 L293.347,52.59 L276.437,52.59 L282.667,2.6 L279.967,0 Z M319.277,8.92 L299.667,8.92 L297.807,25.83 L316.577,25.83 L319.277,8.92 Z"
                        id="形状"></path>
                  <path d="M350.30824,41.91 L382.45824,41.91 L384.22824,31.22 L348.54824,31.22 L353.00824,0 L405.59824,0 L403.82824,15.15 L385.98824,15.15 L386.91824,8.92 L369.91824,8.92 L367.21824,22.3 L402.89824,22.3 L399.36824,46.37 C399.36824,47.2366667 399.16824,48.0566667 398.76824,48.83 C398.36824,49.6033333 397.824907,50.27 397.13824,50.83 C396.45824,51.39 395.701573,51.8233333 394.86824,52.13 C394.02824,52.4366667 393.144907,52.59 392.21824,52.59 L392.21824,52.59 L345.84824,52.59 L347.61824,38.29 L350.30824,41.91 Z"
                        id="路径"></path>
                  <polygon id="路径"
                           points="418.4186 0 469.2486 0 468.4086 8.92 438.9586 8.92 437.1886 20.44 464.7886 20.44 463.9486 29.36 435.3286 29.36 433.5686 43.67 464.7886 43.67 463.9486 52.59 413.9586 52.59 421.1186 3.53"></polygon>
                  <polygon id="路径"
                           points="508.3642 52.59 490.5242 52.59 496.7542 8.92 477.1442 8.92 478.0742 0 535.1242 0 533.3642 8.92 514.5942 8.92"></polygon>
                  <polygon id="路径"
                           points="547.94908 0 598.77908 0 597.93908 8.92 568.48908 8.92 566.71908 20.44 594.31908 20.44 593.47908 29.36 564.85908 29.36 563.09908 43.67 594.31908 43.67 593.47908 52.59 543.48908 52.59 550.64908 3.53"></polygon>
                  <path d="M612.15468,52.59 L612.15468,52.59 C611.288013,52.59 610.48468,52.4366667 609.74468,52.13 C608.998013,51.8233333 608.361347,51.39 607.83468,50.83 C607.308013,50.27 606.951347,49.6033333 606.76468,48.83 C606.58468,48.0566667 606.618013,47.2366667 606.86468,46.37 L606.86468,46.37 L613.08468,0 L669.30468,0 L668.37468,8.92 L629.16468,8.92 L624.70468,43.67 L659.45468,43.67 L663.91468,40.14 L662.14468,52.59 L612.15468,52.59 Z"
                        id="路径"></path>
                </g>
              </g>
              <g id="shape_subtext"
                 transform="translate(267.989375, 303.357444)"
                 fill="#f3f3f3"
                 fill-rule="nonzero"
                 stroke="#f3f3f3"
                 stroke-width="0.051">
                <g id="group">
                  <path d="M8.83,0 C10.7766667,0 12.7033333,0.356666667 14.61,1.07 L14.61,1.07 L13.63,6.08 L12.76,6.45 C11.9,5.91666667 11.05,5.49666667 10.21,5.19 C9.37,4.88333333 8.69333333,4.73 8.18,4.73 L8.18,4.73 C7.71333333,4.73 7.34,4.82333333 7.06,5.01 C6.78666667,5.19 6.65,5.43666667 6.65,5.75 L6.65,5.75 C6.65,6.13666667 6.86,6.47 7.28,6.75 C7.7,7.02333333 8.4,7.38666667 9.38,7.84 L9.38,7.84 C10.5266667,8.35333333 11.47,8.83 12.21,9.27 C12.95,9.71 13.5966667,10.3133333 14.15,11.08 C14.7033333,11.8466667 14.98,12.7966667 14.98,13.93 L14.98,13.93 C14.98,15.1766667 14.6366667,16.3066667 13.95,17.32 C13.2633333,18.34 12.2833333,19.1466667 11.01,19.74 C9.74333333,20.3333333 8.26666667,20.63 6.58,20.63 L6.58,20.63 C4.49333333,20.63 2.3,20.24 2.88657986e-15,19.46 L2.88657986e-15,19.46 L0.9,14.11 L1.51,13.74 C2.45,14.46 3.46,15.03 4.54,15.45 C5.61333333,15.87 6.51,16.08 7.23,16.08 L7.23,16.08 C7.80333333,16.08 8.22,15.9833333 8.48,15.79 C8.73333333,15.59 8.86,15.3466667 8.86,15.06 L8.86,15.06 C8.86,14.6333333 8.64,14.27 8.2,13.97 C7.76,13.6766667 7.04666667,13.3133333 6.06,12.88 L6.06,12.88 C4.93333333,12.3866667 4.00666667,11.92 3.28,11.48 C2.55333333,11.04 1.92333333,10.4366667 1.39,9.67 C0.856666667,8.89666667 0.59,7.94666667 0.59,6.82 L0.59,6.82 C0.59,5.52666667 0.936666667,4.36333333 1.63,3.33 C2.33,2.29666667 3.30333333,1.48333333 4.55,0.89 C5.80333333,0.296666667 7.23,0 8.83,0 L8.83,0 Z"
                        id="路径"></path>
                  <polygon id="路径"
                           points="27.496 13.86 27.436 15.03 27.676 20.26 21.526 20.26 21.836 15.03 21.806 14.08 15.436 0.36 21.896 0.36 22.386 2.03 24.636 8.02 25.006 8.02 27.586 2.24 28.296 0.36 34.566 0.36"></polygon>
                  <polygon id="路径"
                           points="53.9415 0.24 53.6015 13.77 53.8515 20.26 47.1415 20.26 41.5515 9.62 41.1815 9.62 41.1515 13.06 41.3915 20.26 35.7915 20.26 36.1015 14.42 35.7915 0.36 42.5015 0.36 48.0915 11 48.4615 11 48.2815 0.58"></polygon>
                  <path d="M69.314,0.36 L74.754,20.26 L68.514,20.26 L67.964,16.97 L67.934,16.88 L64.914,16.82 L62.394,16.94 L61.874,20.26 L55.844,20.26 L61.874,0.36 L69.314,0.36 Z M65.284,12.42 L67.004,12.39 L65.654,5.87 L65.284,5.87 L63.564,12.39 L65.284,12.42 Z"
                        id="形状"></path>
                  <path d="M86.35425,0.33 C88.5875833,0.33 90.30425,0.88 91.50425,1.98 C92.6975833,3.07333333 93.29425,4.63666667 93.29425,6.67 L93.29425,6.67 C93.29425,8.25 92.9275833,9.64 92.19425,10.84 C91.45425,12.0333333 90.4075833,12.9633333 89.05425,13.63 C87.7009167,14.2966667 86.14425,14.63 84.38425,14.63 L84.38425,14.63 L82.87425,14.63 L83.09425,20.26 L76.90425,20.26 L77.21425,14.42 L76.90425,0.36 L86.35425,0.33 Z M85.24425,10.33 C85.7975833,10.0233333 86.2009167,9.62666667 86.45425,9.14 C86.71425,8.66 86.84425,8.04 86.84425,7.28 L86.84425,7.28 C86.84425,6.38 86.6275833,5.72 86.19425,5.3 C85.7675833,4.88 85.0909167,4.65 84.16425,4.61 L84.16425,4.61 L83.06425,4.7 L82.93425,10.2 L85.24425,10.33 Z"
                        id="形状"></path>
                  <path d="M102.679,0 C104.625667,0 106.552333,0.356666667 108.459,1.07 L108.459,1.07 L107.479,6.08 L106.609,6.45 C105.749,5.91666667 104.899,5.49666667 104.059,5.19 C103.219,4.88333333 102.542333,4.73 102.029,4.73 L102.029,4.73 C101.562333,4.73 101.189,4.82333333 100.909,5.01 C100.635667,5.19 100.499,5.43666667 100.499,5.75 L100.499,5.75 C100.499,6.13666667 100.709,6.47 101.129,6.75 C101.549,7.02333333 102.249,7.38666667 103.229,7.84 L103.229,7.84 C104.375667,8.35333333 105.319,8.83 106.059,9.27 C106.799,9.71 107.445667,10.3133333 107.999,11.08 C108.552333,11.8466667 108.829,12.7966667 108.829,13.93 L108.829,13.93 C108.829,15.1766667 108.485667,16.3066667 107.799,17.32 C107.112333,18.34 106.132333,19.1466667 104.859,19.74 C103.592333,20.3333333 102.115667,20.63 100.429,20.63 L100.429,20.63 C98.3423333,20.63 96.149,20.24 93.849,19.46 L93.849,19.46 L94.749,14.11 L95.359,13.74 C96.299,14.46 97.309,15.03 98.389,15.45 C99.4623333,15.87 100.359,16.08 101.079,16.08 L101.079,16.08 C101.652333,16.08 102.069,15.9833333 102.329,15.79 C102.582333,15.59 102.709,15.3466667 102.709,15.06 L102.709,15.06 C102.709,14.6333333 102.489,14.27 102.049,13.97 C101.609,13.6766667 100.895667,13.3133333 99.909,12.88 L99.909,12.88 C98.7823333,12.3866667 97.8556667,11.92 97.129,11.48 C96.4023333,11.04 95.7723333,10.4366667 95.239,9.67 C94.7056667,8.89666667 94.439,7.94666667 94.439,6.82 L94.439,6.82 C94.439,5.52666667 94.7856667,4.36333333 95.479,3.33 C96.179,2.29666667 97.1523333,1.48333333 98.399,0.89 C99.6523333,0.296666667 101.079,0 102.679,0 L102.679,0 Z"
                        id="路径"></path>
                  <polygon id="路径"
                           points="120.325 15.52 125.435 15.34 125.775 15.77 125.405 20.26 111.375 20.26 111.685 14.42 111.375 0.36 125.645 0.36 125.985 0.8 125.585 5.28 120.695 5.1 117.535 5.1 117.465 7.9 120.515 7.9 123.715 7.81 124.045 8.24 123.675 12.73 120.115 12.63 117.345 12.63 117.315 13.8 117.375 15.52"></polygon>
                  <polygon id="路径"
                           points="141.61075 0.36 141.94075 0.8 141.61075 5.28 137.55075 5.1 137.18075 5.1 137.02075 13.8 137.27075 20.26 131.12075 20.26 131.43075 14.42 131.27075 5.1 130.87075 5.1 126.78075 5.28 126.42075 4.85 126.75075 0.36"></polygon>
                  <polygon id="路径"
                           points="152.92 15.52 158.03 15.34 158.37 15.77 158 20.26 143.97 20.26 144.28 14.42 143.97 0.36 158.24 0.36 158.58 0.8 158.18 5.28 153.29 5.1 150.13 5.1 150.06 7.9 153.11 7.9 156.31 7.81 156.64 8.24 156.27 12.73 152.71 12.63 149.94 12.63 149.91 13.8 149.97 15.52"></polygon>
                  <path d="M169.58575,0.03 C171.352417,0.03 173.01575,0.306666667 174.57575,0.86 L174.57575,0.86 L173.52575,5.75 L173.12575,5.99 C172.592417,5.70333333 171.979083,5.47333333 171.28575,5.3 C170.58575,5.12666667 169.939083,5.04 169.34575,5.04 L169.34575,5.04 C168.092417,5.04 167.189083,5.40333333 166.63575,6.13 C166.082417,6.85666667 165.80575,8.07 165.80575,9.77 L165.80575,9.77 C165.80575,11.7633333 166.12575,13.2166667 166.76575,14.13 C167.399083,15.0366667 168.392417,15.49 169.74575,15.49 L169.74575,15.49 C170.319083,15.49 170.959083,15.4333333 171.66575,15.32 C172.372417,15.2133333 172.992417,15.0566667 173.52575,14.85 L173.52575,14.85 L174.01575,15.16 L173.52575,20.01 C172.292417,20.4033333 170.869083,20.6 169.25575,20.6 L169.25575,20.6 C165.95575,20.6 163.469083,19.74 161.79575,18.02 C160.122417,16.2933333 159.28575,13.78 159.28575,10.48 L159.28575,10.48 C159.28575,7.16 160.182417,4.58666667 161.97575,2.76 C163.769083,0.94 166.30575,0.03 169.58575,0.03 L169.58575,0.03 Z"
                        id="路径"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <!-- 导航 -->
      <div class="home__header--nav">
        <i class="iconfont"
           @click="toggleNavPanel">&#xe62e;</i>
        <ul>
          <li v-for="item in navList"
              :key="item.name"
              :class="{ active: item.name === activedNav }"
              @click="doNavJump(item)">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 首页 -->
    <div class="home__index">
      <div class="container">
        <div class="home__index--link">
          <ul>
            <li v-for="item in shareList"
                :key="item.label">
              <i class="iconfont"
                 v-html="item.icon"
                 @click="openLink(item.link)"></i>
            </li>
          </ul>
        </div>
        <div class="home__index--slogan">
          <div class="main">CODER</div>
          <div class="sub">Code Chagne The World</div>
        </div>
        <div class="home__index--nextbtn">
          <i class="iconfont">&#xe644;</i>
        </div>
      </div>
      <div v-if="isDialogShow"
           class="home__index--qrcode">
        <div class="close-btn"
             @click="isDialogShow = false">
          <i class="iconfont">&#xe69f;</i>
        </div>
        <img :src="qrcodeSrc" />
        <div class="tip">请打开微信扫描二维码</div>
      </div>
    </div>
    <!-- 关于我 -->
    <div class="home__about">
      <div class="home__about--header">
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             width="100%"
             height="50"
             style="
            position: absolute;
            padding-bottom: o;
            margin-bottom: 0;
            fill: white;
            stroke: white;
            bottom: -1px;
            left: 0px;
          "
             viewBox="0 0 100 100"
             preserveAspectRatio="none">
          <path d="M0 100 C 20 0 50 0 100 100 Z"></path>
        </svg>
      </div>
      <div class="home__about--container">
        <div class="photo">
          <img :src="mineSrc" />
        </div>
        <div class="description">
          <div class="description__title title">
            {{ mineDescription.title }}
          </div>
          <div class="description__content"
               v-html="mineDescription.content"></div>
        </div>
      </div>
    </div>
    <!-- 生涯 -->
    <div class="home__career">
      <div class="home__career--item"
           v-for="item in careerList"
           :key="item.label">
        <div class="icon">
          <i class="iconfont"
             v-html="item.icon"></i>
        </div>
        <div class="count">{{ item.count }}</div>
        <div class="label">{{ item.label }}</div>
      </div>
    </div>
    <!-- 职业技能 -->
    <div class="home__skill">
      <div class="home__skill--title title">我的技能</div>
      <div class="home__skill--description">工欲善其事，必先利其器</div>
      <div class="home__skill--items">
        <div class="skill-item"
             v-for="item in skillList"
             :key="item.label">
          <div class="skill-item__icon">
            <i class="iconfont"
               v-html="item.icon"></i>
          </div>
          <div class="skill-item__label">{{ item.label }}</div>
          <div class="skill-item__description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <!-- 作品 -->
    <div class="home__works">
      <div class="home__works--title title">我的作品</div>
      <div class="home__works--description">
        业精于勤而荒于嬉，行成于思而毁于随
      </div>
      <div class="home__works--items">
        <template v-if="!getWorksList.length">
          <div class="works-none">
            <i class="iconfont icon">&#xe760;</i>
            <div class="tip">暂时还没有哦</div>
          </div>
        </template>
        <template v-else>
          <div class="works-item"
               v-for="item in getWorksList"
               :key="item.label">
            <img class="works-item__img"
                 :src="item.background" />
            <div class="works-item__label">{{ item.label }}</div>
          </div>
          <div class="works-item more">更多</div>
        </template>
      </div>
    </div>
    <!-- 博客 -->
    <div class="home__blog">
      <div class="home__blog--title title">我的博客</div>
      <div class="home__blog--description">
        故不积跬步，无以至千里。不积小流，无以成江海
      </div>
      <div class="home__blog--items">
        <template v-if="!getBlogList.length">
          <div class="blog-none">
            <i class="iconfont icon">&#xe760;</i>
            <div class="tip">暂时还没有哦</div>
          </div>
        </template>
        <template v-else>
          <div class="blog-item"
               v-for="item in getBlogList"
               :key="item.label">
            <img class="blog-item__img"
                 :src="item.cover" />
            <div class="blog-item__title">{{ item.title }}</div>
            <div class="blog-item__abstract">{{ item.abstract }}</div>
            <div class="blog-item__pubdate">发布时间：{{ item.pubdate }}</div>
            <div class="blog-item__tag">关键字：{{ item.tag.join(",") }}</div>
          </div>
        </template>
      </div>
      <template v-if="getBlogList.length">
        <div class="home__blog--more">
          <div>访问更多</div>
        </div>
      </template>
    </div>
    <!-- 联系我 -->
    <div class="home__contact">
      <div class="home__contact--title title">联系我</div>
      <div class="home__contact--description">有朋自远方来，不亦悦乎</div>
      <div class="home__contact--form">
        <input class="form-name form-item"
               type="text"
               placeholder="您的姓名" />
        <input class="form-email form-item"
               type="text"
               placeholder="您的邮箱" />
        <input class="form-subject form-item"
               type="text"
               placeholder="主题" />
        <textarea class="form-content form-item"
                  placeholder="内容"></textarea>
        <div class="form-submit">
          <div>提交</div>
        </div>
      </div>
    </div>
    <!-- 友情链接 -->
    <div class="home__links">
      <div class="home__links--title">友情链接</div>
      <div class="home__links--items">
        <div class="links-item"
             v-for="item in linksList"
             :key="item.name"
             @click="openLink(item.link)">
          <div class="links-item__icon">
            <img :src="item.icon" />
          </div>
          <div class="links-item__content">
            <div class="name">{{ item.name }}</div>
            <div class="description">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="home__footer">
      <div class="copyright">Copyright©synapsetec.cn All Rights Reserved.</div>
      <div class="record">
        <a target="_blank"
           href="https://beian.miit.gov.cn/"
           style="display: inline-block; text-decoration: none; color: #939393">
          湘ICP备2021019628号-1
        </a>
      </div>
      <div style="width: 300px; margin: 0 auto; padding: 10px 0">
        <a target="_blank"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43038102001116"
           style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "><img src="../assets/images/record.png"
               style="float: left" />
          <p style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            ">
            湘公网安备 43038102001116号
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import qrcodeSrc from "@i/qrcode.jpg";
import mineSrc from "@i/mine.jpg";
import mineDescription from "@j/mine.json";

import kuroLogo from "@i/links-logo/kuro-logo.svg";

export default {
  name: "Index",

  components: {},

  data() {
    return {
      clientWidth: 0,

      qrcodeSrc,
      mineSrc,
      mineDescription,

      activedNav: "HOME",

      navList: [
        {
          label: "首页",
          name: "HOME",
        },
        {
          label: "关于我",
          name: "ABOUT",
        },
        {
          label: "生涯",
          name: "CAREER",
        },
        {
          label: "作品",
          name: "WORKS",
        },
        {
          label: "博客",
          name: "BLOG",
        },
        {
          label: "联系我",
          name: "CONTACT",
        },
      ],
      shareList: [
        {
          label: "github",
          icon: "&#xe885;",
          link: "https://github.com/NoahAdams-c",
        },
        {
          label: "wechat",
          icon: "&#xe884;",
          link: "wechat#Chenchen_9694_",
        },
        {
          label: "weibo",
          icon: "&#xe883;",
          link: "https://weibo.com/u/6511377319/home",
        },
        {
          label: "twitter",
          icon: "&#xe882;",
          link: "https://twitter.com/ccomb_NB",
        },
        {
          label: "bilibili",
          icon: "&#xe69e;",
          link: "https://space.bilibili.com/39625724",
        },
      ],
      careerList: [
        {
          icon: "&#xe87d;",
          count: 23,
          label: "前端页面",
        },
        {
          icon: "&#xe80e;",
          count: 45,
          label: "NodeJS服务",
        },
        {
          icon: "&#xe623;",
          count: 4,
          label: "Java服务",
        },
        {
          icon: "&#xe615;",
          count: 9,
          label: "Python嵌入式",
        },
      ],
      skillList: [
        {
          icon: "&#xe87d;",
          label: "Html+Css",
          description: "可熟练使用html与css实现各种前端页面",
        },
        {
          icon: "&#xe69a;",
          label: "VueJS",
          description: "能够熟练使用Vue全家桶进行前后端分离的单页应用开发",
        },
        {
          icon: "&#xe80e;",
          label: "NodeJS",
          description:
            "有过多个使用NodeJS开发后端微服务的项目实战经验，熟悉NodeJS内部机制",
        },
        {
          icon: "&#xe623;",
          label: "Java",
          description: "使用Java+SpringBoot进行过多个个人项目的开发",
        },
        {
          icon: "&#xe615;",
          label: "Python",
          description: "使用Python+OpenCV进行过个人嵌入式项目开发",
        },
        {
          icon: "&#xe667;",
          label: "MySQL",
          description: "熟练掌握各类SQL的使用，对SQL优化有一定了解",
        },
        {
          icon: "&#xe619;",
          label: "Redis",
          description: "掌握Redis各类结构的使用，了解Redis的集群模式部署",
        },
        {
          icon: "&#xe900;",
          label: "Linux",
          description:
            "开发环境常年使用Linux系统，了解Linux各类指令的使用，熟悉各类Linux发行版",
        },
        {
          icon: "&#xe63c;",
          label: "Nginx",
          description: "熟悉Nginx的各种配置以及使用Nginx进行负载均衡与节流分流",
        },
      ],
      worksList: [
        {
          background: "default.jpeg",
          label: "测试1",
          link: "",
        },
        {
          background: "default.jpeg",
          label: "测试2",
          link: "",
        },
        {
          background: "default.jpeg",
          label: "测试3",
          link: "",
        },
        {
          background: "default.jpeg",
          label: "测试4",
          link: "",
        },
        {
          background: "default.jpeg",
          label: "测试5",
          link: "",
        },
      ],
      blogList: [
        {
          cover: "test.png",
          title: "NodeJS",
          abstract:
            "简单的说 Node.js 就是运行在服务端的 JavaScript。Node.js 是一个基于Chrome JavaScript 运行时建立的一个平台。Node.js是一个事件驱动I/O服务端JavaScript环境，基于Google的V8引擎，V8引擎执行Javascript的速度非常快，性能非常好。",
          pubdate: "2021-10-01 10:00:39",
          tag: ["nodejs", "web后端"],
        },
        {
          cover: "test.png",
          title: "Vue",
          abstract:
            "Vue (读音 /vjuː/，类似于 view) 是一套用于构建用户界面的渐进式框架。与其它大型框架不同的是，Vue 被设计为可以自底向上逐层应用。Vue 的核心库只关注视图层，不仅易于上手，还便于与第三方库或既有项目整合。另一方面，当与现代化的工具链以及各种支持类库结合使用时，Vue 也完全能够为复杂的单页应用提供驱动。",
          pubdate: "2021-12-09 17:31:48",
          tag: ["vue", "web前端"],
        },
        {
          cover: "test.png",
          title: "Python",
          abstract:
            "Python由荷兰数学和计算机科学研究学会的Guido van Rossum 于1990 年代初设计，作为一门叫做ABC语言的替代品。Python提供了高效的高级数据结构，还能简单有效地面向对象编程。Python语法和动态类型，以及解释型语言的本质，使它成为多数平台上写脚本和快速开发应用的编程语言，随着版本的不断更新和语言新功能的添加，逐渐被用于独立的、大型项目的开发。",
          pubdate: "2020-08-23 10:34:33",
          tag: ["python", "机器学习", "linux"],
        },
      ],
      linksList: [
        {
          icon: "https://fengh.pro/img/logo.svg",
          name: "FENGH",
          description: "Life is measured by thought and action not by time.",
          link: "https://fengh.pro",
        },
        {
          icon: kuroLogo,
          name: "KULO",
          description:
            "移动app、h4、小程序、终端设计、网站定制、品牌形象设计感知有温度的设计。",
          link: "http://uikuro.com",
        },
        {
          icon: "https://uilee.com/static/picture/logo.svg",
          name: "UILEE",
          description: "一名野生设计师",
          link: "https://uilee.com",
        },
        {
          icon:
            "https://cdn.jsdelivr.net/gh/bubao/picgo_db_tiny/logo/dalogo1.png",
          name: "瓦猫杂货铺",
          description: "因为有了危机感，所以会义无反顾。",
          link: "https://bubao.github.io",
        },
      ],

      isDialogShow: false,
    };
  },

  computed: {
    getWorksList() {
      if (this.worksList && this.worksList.length) {
        const newList = this.worksList.map((item) => {
          item.background = require(`../assets/images/work-covers/${item.background}`);
          return item;
        });
        return newList;
      } else {
        return [];
      }
    },
    getBlogList() {
      if (this.blogList && this.blogList.length) {
        const newList = this.blogList.map((item) => {
          item.cover = require(`../assets/images/blog-covers/${item.cover}`);
          return item;
        });
        return newList;
      } else {
        return [];
      }
    },
  },

  watch: {},

  created() {
    this.clientWidth = document.querySelector("html").clientWidth;
    window.addEventListener("resize", () => {
      this.clientWidth = document.querySelector("html").clientWidth;
    });
    window.addEventListener("load", () => {
      this.clientWidth = document.querySelector("html").clientWidth;
    });
  },

  mounted() { },

  methods: {
    /**
     * 更新各导航区域所在位置
     */
    updateNavOffsetTop() {
      this.navList = this.navList.map((item) => {
        if (item.name === "HOME") {
          item.offsetTop = 0;
        } else {
          // 需要减去导航栏的高度，否则导航栏会盖掉一部分内容
          const headerHeight = document.querySelector(".home__header")
            .clientHeight;
          // 留一点视窗距离
          const viewHeight = this.clientWidth < 768 ? 10 : 60;
          // console.log(
          //   document.querySelector(`.home__${item.name.toLowerCase()}`)
          //     .offsetTop
          // )
          item.offsetTop =
            document.querySelector(`.home__${item.name.toLowerCase()}`)
              .offsetTop -
            headerHeight -
            viewHeight;
        }
        return item;
      });
    },

    /**
     * 页面滚动事件
     */
    onPageScroll(e) {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      } else {
        window.event.cancelBubble = true;
      }
      this.updateNavOffsetTop();
      const target = e.target;
      // 当滚动到指定区域时导航激活
      for (let i = 0; i < this.navList.length; i++) {
        if (
          i < this.navList.length - 1 &&
          target.scrollTop >= this.navList[i].offsetTop &&
          target.scrollTop < this.navList[i + 1].offsetTop
        ) {
          this.activedNav = this.navList[i].name;
          break;
        } else if (
          i === this.navList.length - 1 &&
          target.scrollTop >= this.navList[i].offsetTop
        ) {
          this.activedNav = this.navList[i].name;
          break;
        }
      }
      // 当滚动到首页的1/2处时改变header样式
      if (target.scrollTop >= target.clientHeight - target.clientHeight / 2) {
        this.$refs["header"].classList.add("active");
      } else {
        this.$refs["header"].classList.remove("active");
      }
    },

    /**
     * 导航面板展示/隐藏
     */
    toggleNavPanel(event) {
      event.target.classList.toggle("active");
      document
        .querySelector(".home__header--nav ul")
        .classList.toggle("active");
    },

    /**
     * 导航跳转
     */
    doNavJump(navInfo) {
      this.updateNavOffsetTop();
      if (this.clientWidth < 768) {
        document
          .querySelector(".home__header--nav i")
          .classList.remove("active");
        document
          .querySelector(".home__header--nav ul")
          .classList.remove("active");
      }
      const homeDom = document.querySelector(".home");
      if (window.getComputedStyle(homeDom).scrollBehavior) {
        homeDom.scrollTo(0, navInfo.offsetTop);
      } else {
        this.scrollSmoothTo(homeDom, navInfo.offsetTop);
      }
    },

    /**
     * 打开链接
     */
    openLink(link) {
      if (link.indexOf("wechat") < 0) {
        window.open(link);
      } else {
        this.showDialog();
      }
    },

    /**
     * 打开二维码弹窗
     */
    showDialog() {
      this.isDialogShow = true;
    },

    /**
     * 平滑滚动（兼容无法使用scroll-behavior: smooth 样式的浏览器）
     */
    scrollSmoothTo(el, position) {
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = (callback, element) => {
          return setTimeout(callback, 17);
        };
      }
      // 当前滚动高度
      let scrollTop = el.scrollTop;
      // 滚动step方法
      const step = () => {
        // 距离目标滚动距离
        const distance = position - scrollTop;
        // 目标滚动位置
        scrollTop = scrollTop + distance / 5;
        if (Math.abs(distance) < 1) {
          el.scrollTo(0, position);
        } else {
          el.scrollTo(0, scrollTop);
          requestAnimationFrame(step);
        }
      };
      step();
    },
  },
};
</script>

<style lang="scss">
@import "@s/variables.scss";

@media screen and (min-width: 768px) {
  @import "@s/normal.scss";
}
@media screen and (max-width: 767px) {
  @import "@s/small.scss";
}

/* 滚动条整体部分 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* 滚动条中用于拖动的块 */
::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 4px;
  background-color: $main-color;
}
/* 滚动条的轨道 */
::-webkit-scrollbar-track {
  width: 4px;
  box-shadow: inset 0px 0px 3px 0px #aeaeae;
  background-color: #f3f3f3;
}
/* 滚动条两端的按钮 */
::-webkit-scrollbar-button {
  display: none;
}
</style>
